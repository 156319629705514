import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Input,
  Label,
  Modal,
  FormGroup,
  Row,
  Alert,
  Button,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import React, { useState, useEffect } from "react";
import axios from "axios";
import userDummy from "../../assets/images/users/user-dummy-img.jpg";
import { ToastContainer, toast } from "react-toastify";
import { get_cookie } from "../../helpers/get_cookie";
import Loader from "../../Components/Common/Loader";
import forge from "node-forge";
import { tipalti_sandbox,tipalti_production } from "../../config";
import Select from "react-select";
import moment from "moment";
import { Link } from "react-router-dom";

const PaymentHistory = () => {
  document.title = "Instant Payouts | Get Rostered";
  const [utcTime, setUtcTime] = useState("");
  const [payerName] = useState("GETROSTERED");
  const [payeeId, setPayeeId] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [iframeURL, setIframeURL] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [selectedBank, setSelectedBank] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [sysSwiftCodes, setSysSwiftCodes] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [copyState, setCopyState] = useState("copy");
  const [errorMessage, setErrorMessage] = useState("");
  const [workforceData, setWorkforceData] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [hoverIcon, setHoverIcon] = useState(false);

  const authUser = JSON.parse(get_cookie("workauthUser")).user_id;
  // const masterKey = tipalti_sandbox.MASTER_KEY;
  const masterKey = tipalti_production.MASTER_KEY;

  const generateUTCTime = () => {
    const utcTimestamp = Math.floor(new Date().getTime() / 1000);
    setUtcTime(utcTimestamp);
  };

  const loadData = () => {
    axios
      .get("/get-swift-codes")
      .then((res) => {
        setSysSwiftCodes(res);
        console.log(res, "res1234");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    console.log(authUser, masterKey, utcTime, "AUTHIUSERTIME");
    if (authUser && masterKey && utcTime) {
      console.log("AUTHUSER");
      generateIframe();
      updateInstantPayoutsStatus();
    }
  }, [authUser, masterKey, utcTime]);

  const generateIframe = () => {
    const baseURL = tipalti_production.BASE_URL + `payeedashboard/PaymentsHistory`;
     // const baseURL = tipalti_sandbox.BASE_URL + `payeedashboard/PaymentsHistory`;
    
    const compiledParameters = `payer=${payerName}&idap=${authUser}&ts=${utcTime}&redirectto=https://devwork.getrostered.app/instant-payouts`;

    const hmac = forge.hmac.create();
    hmac.start("sha256", masterKey);
    hmac.update(compiledParameters);
    const hashText = hmac.digest().toHex();

    const finalIframeURL = `${baseURL}?${compiledParameters}&hashkey=${hashText}`;
    setIframeURL(finalIframeURL);
    updateInstantPayoutsStatus();
  };

  const updateInstantPayoutsStatus = () => {
    axios
      .post("/update-instantpayouts-status", {
        user_id: authUser,
      })
      .then((res) => {
        // Check if the response status is OK (HTTP 200)
        if (res.message === "Success") {
          console.log("Payee updated successfully.");
        } else {
          console.log("Payee not updated. Error:", res.error);
          // toast.error("Failed to update payee ID.");
        }
      })
      .catch((err) => {
        console.error("Error updating instant payouts status:", err);
        // toast.error("Error updating payee ID status.");
      });
  };

  useEffect(() => {
    loadData();
    generateUTCTime();
    getworkforceData();
    listPayments();
  }, [authUser]);

  const getworkforceData = () => {
    axios
      .post("/get-workforce-data", { user_id: authUser })
      .then((res) => {
        setWorkforceData(res.workforce_data);
        console.log(res, "res12234");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const listPayments = async () => {
    axios
      .post("/get-list-of-payments", {
        user_id: authUser,
      })
      .then((res) => {
        if (res.message === "success") {
          console.log("payment listed successfully:", res.payments);
          const sortedPayments = res.payments.sort(
            (a, b) =>
              new Date(b.transactionCompletionDate).getTime() -
              new Date(a.transactionCompletionDate).getTime()
          );
          console.log("payment listed successfully:", sortedPayments);
          setPaymentList(sortedPayments);
        }
      })
      .catch((err) => {
        console.error("error listing payment list :", err);
      });
  };

  // Check if the user is coming back to the tab
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        const currentUtcTimestamp = Math.floor(new Date().getTime() / 1000);
        if (currentUtcTimestamp - utcTime > 360) {
          // 10 minutes
          toast.info("Session expired. Refreshing...");
          generateUTCTime(); // Refresh the timestamp
          generateIframe(); // Regenerate the iFrame URL
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [utcTime]);

  // Handle bank selection
  const handleBankChange = (selectedOption) => {
    setSelectedBank(selectedOption);
    setErrorMessage("");
    console.log(sysSwiftCodes);
    // Find the swift code for the selected bank
    const selected = sysSwiftCodes.find(
      (bank) => bank.bank_name === selectedOption.value
    );
    console.log(selected);
    setSwiftCode(selected ? selected.swift_code : "");
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    setErrorMessage("");
  };

  // Copy swift code to clipboard
  const copyToClipboard = () => {
    if (swiftCode) {
      navigator.clipboard.writeText(swiftCode);
      setCopyState("copied"); // Change to copied state

      // Reset to copy state after 2 seconds
      setTimeout(() => {
        setSelectedBank("");
        setSwiftCode("");
        setCopyState("copy");
      }, 2000);
    } else {
      setErrorMessage("Please select a bank to copy the SWIFT Code.");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Payments History" />
          {/* <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
            <ModalHeader toggle={toggleModal}>SWIFT Code</ModalHeader>
            <ModalBody>
              <Row>
                <Col xl={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <FormGroup style={{ flex: 1 }}>
                      <Label for="bankSelect">Select Bank</Label>
                      <Select
                        value={selectedBank}
                        id="bankSelect"
                        onChange={handleBankChange}
                        options={sysSwiftCodes?.map((code) => ({
                          value: code.bank_name,
                          label: code.bank_name,
                        }))}
                        placeholder="Select a bank"
                      />
                      {errorMessage && (
                        <div
                          style={{
                            position: "absolute",
                            bottom: "0",
                            left: "0",
                            color: "#e13c3c",
                            fontSize: "0.875em",
                            marginLeft: "1.5em",
                            marginBottom: "0%",
                          }}
                        >
                          {errorMessage}
                        </div>
                      )}
                    </FormGroup>

                    <FormGroup style={{ flex: 1 }}>
                      <Label for="swiftCode">SWIFT Code</Label>
                      <div style={{ display: "flex", alignItems: "center" }}>
{{ marginRight: "8px" }}
                        />
                        <button
                          type="button"
                          onClick={copyToClipboard}
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {copyState === "copy" ? (
                            <i
                              className="bx bxs-copy"
                              style={{ color: "#ffbc00" }}
                            ></i>
                          ) : (
                            <i
                              className="ri-check-fill"
                              style={{ color: "#00cb81" }}
                            ></i>
                          )}
                          <span style={{ marginLeft: "5px" }}>
                            {copyState === "copy" ? "Copy" : "Copied!"}
                          </span>
                        </button>
                      </div>
                    </FormGroup>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal> */}

          <Row>
            {/* <Col xl={12}>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  paddingBottom: "56.25%", // Maintain a 16:9 aspect ratio
                  height: 0,
                  overflow: "hidden",
                }}
              >
                {iframeURL && (
                  <iframe
                    id="liveExample"
                    src={iframeURL}
                    title="iFrame Example"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      border: "none",
                    }}
                  />
                )}
              </div>
              <ToastContainer />

              {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 9999,
                  }}
                >
                  <Loader />
                </div>
              )}
            </Col> */}
            <div className="table-responsive table-card">
              <Table className="table-striped align-middle table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Approval Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Business Name</th>
                    <th scope="col">Payment Method</th>
                    <th scope="col">Amount Submitted</th>
                  </tr>
                </thead>
                {paymentList && paymentList.length > 0 ? (
                  paymentList.map((item, index) => (
                    <tbody key={index}>
                      <tr>
                        <td style={{ color: "#EC1388" }}>#{item.refCode}</td>
                        <td>
                          {item.transactionCompletionDate
                            ? moment(item.transactionCompletionDate).format(
                                "DD/MM/YYYY"
                              )
                            : moment(item?.timesheetData?.updated_at).format(
                                "DD/MM/YYYY"
                              )}
                        </td>
                        <td
                          className={`fw-medium ${
                            ["SUBMITTED"].includes(item.status) &&
                            " text-success"
                          }`}
                        >
                          {item.status.toLowerCase()}
                        </td>
                        <td>
                          {item.timesheetData?.business_name
                            ? item.timesheetData?.business_name
                            : "-"}
                        </td>
                        <td className="fw-medium">
                          {item.paymentMethod == "WIRE_TRANSFER" &&
                            "Wire Transfer"}
                        </td>
                        <td>${item.amountSubmitted.amount}</td>
                      </tr>
                    </tbody>
                  ))
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        No data found
                      </td>
                    </tr>
                  </tbody>
                )}
                {paymentList && paymentList.length > 0 && (
                  <tfoot className="table-light">
                  <tr>
                    <td colSpan="5">Total</td>
                    <td>
                      $
                      {paymentList
                        .reduce((total, item) => total + parseFloat(item.amountSubmitted.amount), 0)
                        .toFixed(2)}
                    </td>
                  </tr>
                </tfoot>
                )}
              </Table>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PaymentHistory;
