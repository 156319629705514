import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
//redux
import { add_cookie } from "../../helpers/get_cookie";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { socialLogin, resetLoginFlag } from "../../store/actions";
import LogoPink from "../../assets/images/LogoPink.png";
import withRouter from "../../Components/Common/withRouter";
//import images

import { GoogleLogin } from "@react-oauth/google";
import AppleLogin from "react-apple-login";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";

const Login = (props) => {
  const dispatch = useDispatch();
  const { user, errorMsg, loading, error } = useSelector((state) => ({
    user: state.Account.user,
    errorMsg: state.Login.errorMsg,
    loading: state.Login.loading,
    error: state.Login.error,
  }));
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const [passwordShow, setPasswordShow] = useState(false);
  const [result, setResult] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    remember: false,
  });
  const [isregistrationCompleted, setRegistrationCompleted] = useState(false);
  const [registrationStep, setRegistrationStep] = React.useState(1);

  const onGoogleLogin = async (token) => {
    let isChecked = document.getElementById("auth-remember-check").checked;
    const decoded_info = jwtDecode(token.credential);
    const formData = {
      idToken: token.credential,
    };
    const response = await axios.post("/google-login", formData);

    if (
      response.status == "200" &&
      response.message != "User registered successfully"
    ) {
      add_cookie(response, isChecked);
      navigate("/overview");
    } else if (response.message === "User is not confirmed.") {
      axios.post("/resend-otp", { email: response.email }).then((res) => {});
      toast.warning(
        "User is not verified!. Redirecting to verification page...",
        { autoClose: 2000 }
      );
      setTimeout(() => {
        navigate("/twoStepVerify", { state: { email: response.email } });
        setIsLoading(false);
      }, 3000);
    } else if (response.message === "Incorrect username or password.") {
      toast.error("Incorrect user email or password!");
      setIsLoading(false);
    } else if (response.message === "User registered successfully") {
      navigate("/twoStepVerify", { state: { email: decoded_info.email } });
      setIsLoading(false);
    }
  };

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.router.navigate, type));
    }
  };

  useEffect(() => {
    // Check if there is a stored URL for redirecting after session expiration
    const redirectUrl = localStorage.getItem("expiredSessionRedirectUrl");
    console.log(redirectUrl);
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(resetLoginFlag());
      }, 3000);
    }
  }, [dispatch, error]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isChecked = document.getElementById("auth-remember-check").checked;
    // Basic validation
    if (!formData.email || !formData.password) {
      setFormErrors({
        email: !formData.email ? "Please enter your email" : "",
        password: !formData.password ? "Please enter your password" : "",
        remember: isChecked,
      });
      return;
    }

    // Clear previous errors
    setFormErrors({});

    try {
      const response = await axios.post("/login", formData);
      if (response.status_code === 200) {
        add_cookie(response, isChecked);
        console.log(response, "login console");
        let payload = {
          user_id: response.user_id,
        };
        axios.post("/profile-complete-data", payload).then((response) => {
          console.log(response, "profile-complete-data Response");
          if (response.error == 0) {
            const userProfile = response.userProfileStatus[0];
            console.log(userProfile.profile_setup_step, "userProfile");
            if (userProfile.profile_setup_completed == 0) {
              setRegistrationCompleted(false);
              setRegistrationStep(userProfile.profile_setup_step);
            } else {
              setRegistrationCompleted(true);
              setRegistrationStep(userProfile.profile_setup_step);
            }
            if (
              userProfile.personal_complete == true &&
              userProfile.availability_complete == true &&
              userProfile.volunteering_complete == true &&
              userProfile.preferences_complete == true &&
              userProfile.work_location_complete == true
            ) {
              // setProfileCompleted(true);
              navigate("/overview");
            } else {
              //   setProfileCompleted(false);
              navigate("/workforce-setup");
            }
          } else {
            console.log(response);
          }
        });
      } else if (response.message === "User is not confirmed.") {
        axios.post("/resend-otp", { email: formData.email }).then((res) => {
          console.log(res);
        });
        toast.warning(
          "User is not verified!. Redirecting to verification page...",
          { autoClose: 2000 }
        );
        setTimeout(() => {
          navigate("/twoStepVerify", { state: { email: formData.email } });
        }, 3000);
        setIsLoading(false);
      } else if (response.message === "Incorrect username or password.") {
        // setErrLogin("Incorrect user email or password!");
        toast.error("Incorrect user email or password!");
        setIsLoading(false);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  document.title = "Get Rostered";

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="d-flex">
          <Container className="mt-lg-3 ">
            <Row className="justify-content-center">
              <Link
                to="/"
                className="text-center mb-4 d-inline-block auth-logo"
              >
                <img src={LogoPink} alt="" height="55" />
              </Link>

              <Col md={8} lg={6} xl={5}>
                <Card
                  className="login-card mt-4"
                  style={{ borderRadius: "40px" }}
                >
                  <CardBody className="p-5">
                    {/* <div className="text-center mt-sm-4 mb-4 text-white-50">
                                            <div>
                                                <Link to="/" className="d-inline-block auth-logo">
                                                    <img src={logoRoster} alt="" height="30" />
                                                </Link>
                                            </div>

                                        </div> */}

                    <div className="text-center mt-0 mb-2 ">
                      <div>
                        <Label
                          style={{
                            fontSize: "22px",
                            fontWeight: "700",
                            fontFamily: "Poppins",
                          }}
                        >
                          Let's get you Logged in!
                        </Label>
                      </div>
                    </div>
                    <div className="text-center mb-4">
                      <Label
                        style={{ fontSize: "12px", fontFamily: "Poppins" }}
                      >
                        Enter your information below
                      </Label>
                    </div>

                    {errorMsg && errorMsg ? (
                      <Alert color="danger"> {errorMsg} </Alert>
                    ) : null}
                    <div className="p-2 mt-4">
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3 form-group ">
                          <div className="form-icon">
                            {/* <Label htmlFor="email" className="form-label text-pink login_label_control">User email</Label> */}
                            <Input
                              name="email"
                              className={
                                "form-control pe-5 form-control-icon" +
                                (formErrors.email ? " is-invalid" : "")
                              }
                              placeholder="Enter email"
                              type="email"
                              onChange={handleInputChange}
                              value={formData.email}
                              invalid={
                                formErrors.email !== undefined &&
                                formErrors.email !== ""
                              }
                              style={{
                                borderRadius: "10px",
                                paddingLeft: "2.5rem",
                              }}
                            />
                            <i
                              className="ri-mail-fill"
                              style={{ color: "grey" }}
                            ></i>
                          </div>
                          {formErrors.email && (
                            <FormFeedback type="invalid">
                              {formErrors.email}
                            </FormFeedback>
                          )}
                        </div>

                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <i
                            className="ri-lock-2-fill position-absolute mt-2 ms-3 icon-left"
                            style={{ color: "grey" }}
                          ></i>

                          <Input
                            name="password"
                            value={formData.password}
                            type={passwordShow ? "text" : "password"}
                            className="form-control pe-5"
                            placeholder="Enter Password"
                            onChange={handleInputChange}
                            invalid={
                              formErrors.password !== undefined &&
                              formErrors.password !== ""
                            }
                            style={{
                              borderRadius: "10px",
                              paddingLeft: "2.5rem",
                            }}
                          />
                          {formErrors.password && (
                            <FormFeedback type="invalid">
                              {formErrors.password}
                            </FormFeedback>
                          )}
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted me-2"
                            type="button"
                            id="password-addon"
                            onClick={() => setPasswordShow(!passwordShow)}
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>

                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            id="auth-remember-check"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Remember me
                          </Label>
                          <div className="float-end">
                            <Link
                              to="/forgot-password"
                              className="text-bold"
                              style={{
                                fontWeight: "600",
                                color: "black",
                                fontSize: 12,
                                color: "rgba(0, 46, 70, 1)",
                              }}
                            >
                              Forgot password?
                            </Link>
                          </div>
                        </div>
                        <div
                          className={`text-bold mt-3  ${
                            result === false ? "d-block" : "d-none"
                          }`}
                        >
                          <b className="text-danger">
                            <p> These credentials do not match our records!</p>
                          </b>
                        </div>

                        {/* <div style={{ display: "block", width: "fit-content", margin: "auto" }}> */}
                        <div className="mt-4 mb-3">
                          <Button
                            style={{
                              border: "none",
                              borderRadius: "10px",
                            }}
                            className="btn btn-signup  w-100 text-white"
                            disabled={isLoading ? true : false}
                            type="submit"
                          >
                            {isLoading ? (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                Loading...{" "}
                              </Spinner>
                            ) : null}{" "}
                            Log In
                          </Button>
                        </div>

                        {/* </div> */}
                        <div className="mt-3 mb-3 text-center">
                          <p className="mb-0">
                            Don't have an account ?{" "}
                            <Link
                              to="/register"
                              className="fw-semibold "
                              style={{
                                fontWeight: "600",
                                color: "rgba(0, 46, 70, 1)",
                              }}
                            >
                              SignUp{" "}
                            </Link>{" "}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "block",
                            width: "fit-content",
                            margin: "auto",
                          }}
                        >
                          <GoogleLogin
                            className="google-btn d-flex justfy center "
                            style={{ borderRadius: "40px" }}
                            size={"large"}
                            text={"signin_with"}
                            onSuccess={(credentialResponse) => {
                              console.log(credentialResponse);
                              onGoogleLogin(credentialResponse);
                            }}
                            onError={() => {
                              console.log("Login Failed");
                            }}
                          />
                        </div>
                        <div
                          className="mt-2"
                          style={{
                            display: "block",
                            width: "fit-content",
                            margin: "auto",
                          }}
                        >
                          <AppleLogin
                            clientId="apple.getrostered.work"
                            scope="name email"
                            responseType="code id_token"
                            responseMode="form_post"
                            redirectURI="https://devwork.getrostered.app/server/public/api/apple-login-callback"
                            designProp={{ height: 40, width: 180 }}
                          />
                        </div>
                      </form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <ToastContainer />
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
