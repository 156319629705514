import React, { useEffect, useState } from "react";
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap";
import Loader from "../../Components/Common/Loader";
import { Link, useNavigate, Navigate } from "react-router-dom";
import classnames from "classnames";
import { get_cookie } from "../../helpers/get_cookie";
import axios from "axios";
// import Pusher from "pusher-js";
import moment from "moment-timezone";
import { beampusher } from "../../config";
import * as PusherPushNotifications from "@pusher/push-notifications-web";

//SimpleBar
import SimpleBar from "simplebar-react";

const NotificationDropdown = () => {
  const user_id = JSON.parse(get_cookie("workauthUser")).user_id;
  const timezone = JSON.parse(get_cookie("workauthUser")).timezone;
  const access_token = JSON.parse(get_cookie("workauthUser")).access_token;
  const user_ids = String(user_id);
  // console.log(user_id, "user_id123243");

  const navigate = useNavigate();

  // navigator.serviceWorker.ready.then(registration => {
  //   // Get user_id
  //   const user_id = JSON.parse(get_cookie("workauthUser")).user_id;

  //   // Send user_id to service worker
  //   registration.active.postMessage({
  //     type: 'USER_ID_UPDATE',
  //     user_id: user_id
  //   });
  // });

  //Dropdown Toggle
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
  const [allNotification, setAllNotification] = useState("");
  const [notificationCount, setNotificationCount] = useState(0);
  const [workforceId, setWorkforceId] = useState(null);
  const [notificationData, setNotificationData] = useState(null);
  const [toast5, setToast5] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);

  const getNotificationData = async () => {
    setisLoading(true);
    const formData = {
      user_id: user_id,
    };
    try {
      const res = await axios.post("/get-all-notification-messages", formData);
      // Convert logged_at time to human-readable format for each notification
      const updatedNotifications = res.messages_with_urls.map(
        (notification) => ({
          ...notification,
          logged_at_formatted: formatDate(notification.logged_at),
        })
      );
      setWorkforceId(res.workforce_id);
      setNotificationCount(res.unread_count);
      setAllNotification(updatedNotifications);
      console.log(res, "geosearch");
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setisLoading(false);
    }
  };

  useEffect(() => {
    getNotificationData();
    // // --->localhost
    if (('serviceWorker' in navigator)) {
    const beamsClient = new PusherPushNotifications.Client({
      instanceId: beampusher.INSTANCE_ID,
    });

    const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
      url: beampusher.WEB_URL + "server/public/api/pusher-trigger",
    });
    // --->dev
    // const beamsClient = new PusherPushNotifications.Client({
    //   instanceId: beampusher.INSTANCE_ID,
    // });

    // const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
    //   url: beampusher.WEB_URL+"server/public/api/pusher-trigger",
    // });

    beamsClient
      .start()
      .then(() => beamsClient.setUserId(user_ids, beamsTokenProvider))
      .then((response) => {
        console.log(response, "response1213");
        console.log(`Successfully authenticated with Pusher Beams`);
      })
      .catch(console.error);

    beamsClient
      .getUserId()
      .then((userId) => {
        console.log(userId, user_ids, "userId1213456");
        if (userId !== user_ids) {
          console.log(userId, "userId1213");
          // Unregister for notifications
          return beamsClient.stop();
        }
      })
      .catch(console.error);

    // Listen for messages from the service worker
    const broadcast = new BroadcastChannel("notification-channel");
    broadcast.onmessage = (event) => {
      console.log("Message received from service worker:", event.data);
      if (event.data.unread_count !== undefined) {
        // setUnreadCount(event.data.unread_count);
        setNotificationCount(event.data.unread_count);
      }
    };

    // Clean up the broadcast channel on component unmount
    return () => {
      broadcast.close();
    };
  }
  }, [workforceId, user_ids]);

  const formatDate = (logDate) => {
    // const australianTime = moment.tz(logDate, "Australia/sydney");
    const timeFromNow = moment().diff(logDate, "seconds");
    const formattedTimeFromNow = moment
      .duration(timeFromNow, "seconds")
      .humanize();
    return `${formattedTimeFromNow}`;
  };

  //Tab
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  // Define your array of colors
  const colors = [
    "#fd99a8", // Plum
    "#FFA07A", // LightSalmon
    "#FFD700", // Gold
    "#90df90", // PaleGreen
    "#8bdddd", // PaleTurquoise
    "#af91e3", // Lavender
    "#f791eb", // LightPink
    "#add8e6", // LightBlue
    "#ffcccb", // LightCoral
    "#ffb6c1", // LightPink
  ];

  // Function to assign a color based on index
  function getColor(index) {
    return colors[index % colors.length];
  }

  const toggleNotificationDropdown = async () => {
    setisLoading(true);
    setIsNotificationDropdown(!isNotificationDropdown);
    try {
      await getNotificationData();
      setNotificationCount(0);
      // console.log(allNotification, "allNotification");
      const unread_notification_ids = allNotification
        .filter((log) => log.log_status === "unread")
        .map((log) => log.id);
      const formData = {
        user_id: user_id,
        unread_notification_ids: unread_notification_ids,
      };
      const res = await axios.post("/update-notification-status", formData);
      console.log(res.message);
      // setNotificationCount(0);
      getNotificationData();
    } catch (error) {
      console.error("Error occurred", error);
    } finally {
      setisLoading(false);
    }
  };

  const OpenNotification = (
    shiftId,
    shiftStatus,
    notificationId,
    notificationLog,
    notification
  ) => {
    
    updateNotificationStatus(notificationId);
    if (shiftId && (notificationLog === 4 || notificationLog === 3)) {
      navigate(`/activity/${shiftId}`);
      // <Navigate to={{ pathname: "/activity"}} />
    } else if (shiftId &&  (notificationLog === 1)) {
      navigate(`/Job_Offers/${shiftId}`);
      // navigate("AvailableShifts", { opentriggerId: shiftId });
    }
  };

  const formatCreatedAt = (created_at, currentTimezone = 'UTC') => {
    const createdDate = moment.tz(created_at, "YYYY-MM-DD HH:mm:ss", timezone).tz(currentTimezone);
    return createdDate.fromNow();
  };

  const updateNotificationStatus = async (notificationId) => {
    if (user_id) {
      try {
        const formData = {
          user_id: user_id,
          unread_notification_ids: [notificationId],
        };

        const response = await axios.post(
          `/update-notification-status`,
          formData
        );
        if (response && response.data) {
          setAllNotification((prevNotifications) =>
            prevNotifications.map((notif) =>
              notif.id === notificationId
                ? { ...notif, log_status: "read" }
                : notif
            )
          );
          getNotificationData();
        } else {
          console.error("Invalid response format:", response);
        }
      } catch (error) {
        // console.log("Error updating notification status:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        >
          <i className="bx bx-bell fs-22"></i>
          <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
            {notificationCount}
            <span className="visually-hidden">unread messages</span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="dropdown-head bg-primary bg-pattern rounded-top">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 fs-16 fw-semibold text-white">
                    {" "}
                    Notifications{" "}
                  </h6>
                </Col>
              </Row>
            </div>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1" className="py-2 ps-2">
              <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                {/* Check if allNotification is an array before mapping */}
                {Array.isArray(allNotification) &&
                allNotification.length > 0 ? (
                  allNotification.map((notification, index) => (
                    <div
                      key={index}
                      onClick={() =>
                        OpenNotification(
                          notification.related_id,
                          notification.shift_status,
                          notification.id,
                          notification.log_type,
                          notification
                        )
                      }
                      className="text-reset notification-item d-block dropdown-item position-relative"
                    >
                      {/* {console.log(allNotification,'allNotification')} */}
                      <div className="d-flex">
                        {notification.s3Url !== null ? (
                          // Render the profile image if available
                          <div className="avatar-xs me-3">
                            <Link to="#" className="">
                              <img
                                src={notification.s3Url}
                                alt=""
                                title={notification.business_name}
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  borderRadius: "50%",
                                  fontSize: "15px",
                                  border:
                                    notification.log_type === 1
                                      ? "1px solid #50c3e6"
                                      : notification.log_type === 2
                                      ? "1px solid #9C27B0"
                                      : notification.log_type === 3
                                      ? "1px solid #3db583"
                                      : notification.log_type === 4
                                      ? "1px solid #f8bf3b"
                                      : notification.log_type === 5
                                      ? "1px solid #e13c3c"
                                      : notification.log_type === 6
                                      ? "1px solid #f672a7"
                                      : notification.log_type === 12
                                      ? "1px solid #f672a7"
                                      : notification.log_type === 15
                                      ? "1px solid #e13c3c"
                                      : "none",
                                }}
                              />
                            </Link>
                          </div>
                        ) : (
                          // Render initials inside a colored circle if profile image is not available
                          <div className="avatar-xs me-3">
                            <Link to="#" className="">
                              <div
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  backgroundColor: getColor(
                                    notification.business_name.length
                                  ), // Assign color based on username length
                                  borderRadius: "50%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "15px",
                                  color: "#fff",
                                  border:
                                    notification.log_type === 1
                                      ? "1px solid #50c3e6"
                                      : notification.log_type === 2
                                      ? "1px solid #9C27B0"
                                      : notification.log_type === 3
                                      ? "1px solid #3db583"
                                      : notification.log_type === 4
                                      ? "1px solid #f8bf3b"
                                      : notification.log_type === 5
                                      ? "1px solid #e13c3c"
                                      : notification.log_type === 6
                                      ? "1px solid #f672a7"
                                      : notification.log_type === 12
                                      ? "1px solid #f672a7"
                                      : notification.log_type === 15
                                      ? "1px solid #e13c3c"
                                      : "none",
                                }}
                                title={notification.business_name}
                              >
                                {
                                  notification.business_name
                                    .split(" ") // Split the name into words
                                    .slice(0, 2) // Take only the first two words
                                    .map((word) => word.charAt(0).toUpperCase()) // Get the first character of each word
                                    .join("") // Concatenate the initials together
                                }
                              </div>
                            </Link>
                          </div>
                        )}

                        <div className="flex-grow-1">
                          <Link to="#" className="" style={{ color: "black" }}>
                            {notification.log_message}
                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                              <span>
                                <i className="mdi mdi-clock-outline"></i>{" "}
                                <i>{formatCreatedAt(notification.logged_at)}</i>
                              </span>
                            </p>
                          </Link>
                        </div>
                        <div className="px-2 fs-15">
                          {notification.log_type === 1 ? (
                            <span className="" style={{ color: "#50c3e6" }}>
                              <i className="ri-mail-fill"></i>
                            </span>
                          ) : notification.log_type === 2 ? (
                            <span className="" style={{ color: "#9C27B0" }}>
                              <i className="ri-user-add-fill"></i>
                            </span>
                          ) : notification.log_type === 3 ? (
                            <span className="text-success">
                              <i className="ri-user-follow-fill"></i>
                            </span>
                          ) : notification.log_type === 4 ? (
                            <span className="" style={{ color: "#f8bf3b" }}>
                              <i className="ri-folder-open-fill"></i>
                            </span>
                          ) : notification.log_type === 5 ? (
                            <span className="" style={{ color: "#e13c3c" }}>
                              <i className="ri-user-unfollow-fill"></i>
                            </span>
                          ) : notification.log_type === 6 ? (
                            <span className="" style={{ color: "#f672a7" }}>
                              <i className="ri-edit-box-fill"></i>
                            </span>
                          ) : notification.log_type === 12 ? (
                            <span className="" style={{ color: "#f672a7" }}>
                              <i className="ri-file-edit-fill"></i>
                            </span>
                          ) : notification.log_type === 15 ? (
                            <span className="" style={{ color: "#e13c3c" }}>
                              <i className="ri-user-unfollow-fill"></i>
                            </span>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "300px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "48px",
                        color: "#6c757d",
                        marginBottom: "16px",
                      }}
                    >
                      <i className=" bx bx-bell-off"></i>
                    </div>
                    <p
                      style={{
                        margin: 0,
                        color: "#6c757d",
                        fontSize: "16px",
                      }}
                    >
                      No notifications found.
                    </p>
                  </div>
                )}
                {isLoading && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      background: "rgba(255, 255, 255, 0.8)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 9999,
                    }}
                  >
                    <Loader />
                  </div>
                )}
              </SimpleBar>
            </TabPane>
          </TabContent>
        </DropdownMenu>
      </Dropdown>
      {notificationData && (
        <Toast
          isOpen={toast5}
          style={{
            position: "absolute",
            top: "16px",
            right: "16px",
            zIndex: "1050",
            boxShadow: "4px 4px 5px #efdefd",
            borderRadius: "6px",
          }}
        >
          <ToastHeader
            toggle={() => setToast5(!toast5)}
            style={{
              color: "white",
              backgroundColor: "#f672a7",
              zIndex: "1050",
            }}
          >
            {notificationData.s3Url ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={notificationData.s3Url}
                  alt=""
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    marginRight: "8px",
                  }}
                />
                <span className="fw-semibold me-auto">
                  {notificationData.worker_name}
                </span>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    backgroundColor: getColor(
                      notificationData.worker_name.length
                    ),
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "12px",
                    color: "#fff",
                    marginRight: "8px",
                  }}
                >
                  {notificationData.worker_name
                    .split(" ")
                    .slice(0, 2)
                    .map((word) => word.charAt(0).toUpperCase())
                    .join("")}
                </div>
                <span className="fw-semibold me-auto">
                  {notificationData.worker_name}
                </span>
              </div>
            )}
          </ToastHeader>
          <ToastBody>{notificationData.message}</ToastBody>
        </Toast>
      )}
    </React.Fragment>
  );
};

export default NotificationDropdown;
