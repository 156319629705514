import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Accordion,
  AccordionItem,
  Badge,
  Collapse,
  Label,
  Input,
  Button,
} from "reactstrap";
import { ClipLoader } from "react-spinners";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import userDummy from "../../assets/images/users/user-dummy-img.jpg";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { get_cookie } from "../../helpers/get_cookie";
import axios from "axios";
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import Loader from "../../Components/Common/Loader";
import avatar9 from "../../assets/images/small/shifts.png";
import avatar10 from "../../assets/images/small/clock.png";
import SimpleBar from "simplebar-react";
import { Data } from "@react-google-maps/api";
import { useParams, useLocation } from "react-router-dom";

const Activity = () => {
  document.title = "Activity | Get Rostered";

  const location = useLocation();
  console.log(location, " location state");
  const { NotificationTriggerId } = location.state || {};

  const [data, setdata] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUserName] = useState(null);
  const [buttonVisible, setButtonVisible] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [logfrom, setlogfrom] = useState();
  const [statusText, setStatusText] = useState("");
  const [userProfileImg, setUserProfileImg] = useState("");
  const [userimageurl, setuserimageurl] = useState([]);
  const [firstindex, setfirstindex] = useState([]);
  const [firstname, setFirstName] = useState([]);
  const [lastName, setLastName] = useState([]);
  const [activity, setactivity] = useState();
  const [shift_count, setshiftstatus] = useState();
  const [shift_status, setshiftscount] = useState();

  const authUser_id = JSON.parse(get_cookie("workauthUser")).user_id;
  const s3ImgUrl =
    "https://s3.ap-southeast-2.amazonaws.com/devwork.getrostered.app/workforce-profile-pictures/";

  const profleImageUrl = userimageurl ? `${s3ImgUrl}${userimageurl}` : null;

  const params = useParams();
  const related_id = params.id;
  console.log(related_id, "related_id");

  useEffect(() => {
    submitactivity(related_id);
  }, [related_id]);

  useEffect(() => {
    loadTable(authUser_id);
  }, []);

  function convertDateToWeekDayAndDate(dateString) {
    const date = new Date(dateString);
    const options = { weekday: "short", day: "numeric" }; // Display weekday and day
    const formattedDate = date.toLocaleDateString("en-US", options);
    const [weekDay, day] = formattedDate.split(" ");

    return { weekDay, day };
  }

  const loadTable = (userId) => {
    const formData = {
      id: authUser_id,
      mob_log: false,
    };
    axios
      .post("/activity-data", formData)
      .then((res) => {
        console.log(res.data, "activitylogs");
        setdata(res.data.activityLogs);
        setuserimageurl(res.data.workprofile.profile_picture);
        setFirstName(res.data.workprofile.first_name);
        setLastName(res.data.workprofile.last_name);
        setshiftstatus(res.data.shift_count);
        setshiftscount(res.data.shift_status);
      })
      .then((err) => {
        console.error(err);
      });
  };

  console.log(firstname, "imagee");

  const formatTime = (timeString) => {
    const time = new Date(`2022-01-01T${timeString}`);
    return time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  // console.log(data[0]?.id,"iddddddd");

  useEffect(() => {
    // Load the details of the first item in the activity array when the component mounts
    if (data?.length > 0 && NotificationTriggerId == undefined) {
      console.log(NotificationTriggerId, "notif");
      submitactivity(data[0]?.related_id);
    } else {
      console.log(NotificationTriggerId, "notifica");
      submitactivity(NotificationTriggerId);
    }
  }, [data]);

  const submitactivity = (id) => {
    setButtonVisible((prevButtonVisible) => [
      ...prevButtonVisible,
      { id: id, isVisible: true },
    ]);

    setIsLoading(true); // Activate loader

    const formData = {
      id: id,
    };
    axios
      .post("/activity-detail", formData)
      .then((response) => {
        setactivity(response.data);
      })
      .then((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false); // Deactivate loader
      });
  };

  console.log(data, "dmkdmkmkd");

  const statusupdate = (id, value = "") => {
    console.log(id, "id");
    const formData = {
      id: id,
      activity_status: value,
      user_id: authUser_id,
    };

    setIsLoading(true);
    axios
      .post("/update-activity-status", formData)
      .then((response) => {
        if (response.error === 0) {
          toast.error(response.message);
        } else {
          setButtonVisible((prevButtonVisible) => {
            // Map over the array and update visibility for the specific id
            return prevButtonVisible.map((button) =>
              button.id === id ? { ...button, isVisible: false } : button
            );
          });
          toast.success(response.message);
          submitactivity(id);
        }
        setIsLoading(true);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  console.log(profleImageUrl, "profleImageUrlprofleImageUrl");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Activity" pageTitle="Activity" />
          <Row>
            <Col lg={12}>
              <Row>
                <Col xs={12}>
                  <div className="d-flex flex-row align-items-center text-center">
                    <div className="avatar-lg mr-2">
                      {profleImageUrl ? (
                        <React.Fragment>
                          <img
                            src={profleImageUrl}
                            alt="user-img"
                            className="img-thumbnail h-100 w-100 rounded-circle"
                          />
                        </React.Fragment>
                      ) : (
                        <img
                          src={avatar10}
                          alt="imageuser"
                          className="img-thumbnail h-100 w-100 rounded-circle"
                        />
                      )}
                    </div>

                    <h4>
                      Good Day, {firstname} {lastName}!
                    </h4>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={5}>
                  <Card>
                    <CardHeader className="d-flex justify-content-start align-items-center">
                      <i className="text-d fs-18 me-1 bx bx-calendar-check"></i>
                      <h5 className="mb-0 text-d">Shifts {shift_status}</h5>
                    </CardHeader>
                    <CardBody>
                      <div className="mx-n3">
                        <SimpleBar
                          autoHide={false}
                          style={{ maxHeight: "295px", overflowY: "auto" }}
                          className="simplebar-track-primary px-3"
                        >
                          {data?.map((item, index) => {
                            const { weekDay, day } =
                              convertDateToWeekDayAndDate(
                                item.formatted_logged_at
                              );

                            return (
                              <div
                                key={index}
                                className="d-flex align-items-center py-0"
                              >
                                <div className="col-auto">
                                  <div className="avatar-sm p-1 py-2 h-auto bg-light rounded-3">
                                    <div className="text-center">
                                      <h5 className="mb-0">{weekDay}</h5>
                                      <div className="text-muted">
                                        {day}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                  <div className="fs-11 text mt-4">
                                    <p className="text-primary mb-0">
                                      {item.business_name}
                                    </p>
                                    <p>
                                
                                      {item.log_message}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex-shrink-0 ms-2">
                                  <Button
                                    type="button"
                                    color="info"
                                    outline
                                    onClick={() =>
                                      submitactivity(item.related_id)
                                    }
                                    disabled={isLoading} // Disable the button when the loader is active or if it's already clicked
                                  >
                                    Open
                                  </Button>
                                </div>
                              </div>
                            );
                          })}
                        </SimpleBar>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs={7}>
                  <Card style={{ height: "380px" }}>
                    <CardHeader className="d-flex justify-content-start align-items-center">
                      <i className="text-d fs-18 me-1 bx bx-calendar-check"></i>
                      <h5 className="mb-0 text-d">Shift Details</h5>
                    </CardHeader>
                    <CardBody>
                      {isLoading ? (
                        <div
                          style={{
                            position: "relative",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            background: "rgba(255, 255, 255, 0.8)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 9999,
                          }}
                        >
                          <Loader />
                        </div>
                      ) : (
                        <div className="mx-n3">
                          <SimpleBar
                            autoHide={false}
                            style={{ maxHeight: "350px" }}
                            className="simplebar-track-primary px-3"
                          >
                            {isLoading ? (
                              <div
                                style={{
                                  position: "relative",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  background: "rgba(255, 255, 255, 0.8)",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  zIndex: 9999,
                                }}
                              >
                                <Loader />
                              </div>
                            ) : (
                              activity?.map((item, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center py-3"
                                >
                                  <div className="flex-grow-1">
                                    <div>
                                      <h5 className="fs-20 mb-2 text-primary">
                                        {item.business_name}
                                      </h5>
                                      <span className="hstack gap-4 mb-2">
                                        <p className="fs-15 mb-0 bold">
                                          <b>{item.site_name}</b>
                                        </p>
                                        <p className="fs-15 text-muted mb-0">
                                          {item.suburb}
                                        </p>
                                      </span>
                                      <span className="hstack gap-4 mb-2">
                                        <p className="fs-13 mb-0">
                                          Shift Date:
                                        </p>
                                        <p className="fs-13 text-muted mb-0">
                                          {new Date(
                                            item.shift_date
                                          ).toLocaleDateString("en-US", {
                                            month: "short",
                                            day: "numeric",
                                            year: "numeric",
                                          })}
                                        </p>
                                      </span>
                                      <span className="hstack gap-4 mb-2">
                                        <p className="fs-13 mb-0">Time:</p>
                                        <p className="fs-13 text-muted mb-0">
                                          {formatTime(item.shift_start_time)} To{" "}
                                          {formatTime(item.shift_end_time)}
                                        </p>
                                      </span>
                                      <span className="vstack mb-">
                                        <p className="fs-13 mb-0">Notes :</p>
                                        <p className="fs-13 text-muted mb-0">
                                          {item.business_notes ||
                                            "No notes available."}
                                        </p>
                                      </span>
                                      <span className="vstack mt-4">
                                        <span className="vstack mt-4">
                                          <Row>
                                            <Col md={3}>
                                              {buttonVisible && (
                                                <>
                                                  {item.shift_acceptance_status !==
                                                    "Accepted" &&
                                                    item.shift_acceptance_status !==
                                                      "Declined" && (
                                                      <Button
                                                        type="button"
                                                        color="info"
                                                        outline
                                                        onClick={() =>
                                                          statusupdate(
                                                            item.id,
                                                            "Accepted"
                                                          )
                                                        }
                                                        disabled={isLoading}
                                                      >
                                                        Accept
                                                      </Button>
                                                    )}
                                                </>
                                              )}
                                              {console.log(
                                                item.shift_acceptance_status,
                                                "hii"
                                              )}
                                              {item.shift_acceptance_status ===
                                                "Accepted" && (
                                                <Badge
                                                  color="success"
                                                  style={{
                                                    fontSize: "0.9rem",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  {item.shift_acceptance_status}
                                                </Badge>
                                              )}
                                              {item.shift_acceptance_status ===
                                                "Declined" && (
                                                <Badge
                                                  color="danger"
                                                  style={{
                                                    fontSize: "0.9rem",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  {console.log("declined")}
                                                  {item.shift_acceptance_status}
                                                </Badge>
                                              )}
                                            </Col>
                                            <Col md={6}>
                                              {buttonVisible && (
                                                <>
                                                  {item.shift_acceptance_status !==
                                                    "Accepted" &&
                                                    item.shift_acceptance_status !==
                                                      "Declined" && (
                                                      <Button
                                                        type="button"
                                                        color="danger"
                                                        outline
                                                        onClick={() =>
                                                          statusupdate(
                                                            item.id,
                                                            "Declined"
                                                          )
                                                        }
                                                        disabled={isLoading}
                                                      >
                                                        Decline
                                                      </Button>
                                                    )}
                                                </>
                                              )}
                                            </Col>
                                          </Row>
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))
                            )}
                          </SimpleBar>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Activity;
