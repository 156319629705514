import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Label,
  Modal,
  ModalHeader,
  Button,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import BootstrapTheme from "@fullcalendar/bootstrap";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import "../../assets/scss/fullcalender.scss";
import * as moment from "moment";
import Flatpickr from "react-flatpickr";
import { ToastContainer, toast } from "react-toastify";
import { get_cookie } from "../../helpers/get_cookie";
import axios from "axios";

const MyUnavailability = () => {
  //Calender ref
  const calendarRef = useRef(null);

  const [UnavailableModal, setUnavailableModal] = useState(false);

  const [fromTime, setFromTime] = useState();
  const [toTime, setToTime] = useState();
  const [isUnAvailable, setIsUnAvailable] = useState(true);
  const [unavailabilityData, setUnavailabilityData] = useState(null);
  // const [currentMonth, setCurrentMonth] = useState("");
  // const [currentMonthIndex, setCurrentMonthIndex] = useState(0);
  // States to manage current year and month
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(""); // Title of the current month (e.g., "November 2024")
  const [currentMonthIndex, setCurrentMonthIndex] = useState(
    new Date().getMonth()
  ); // Index of the current month (0 = January, 11 = December)

  // modal data's
  const [currentDate, setCurrentDate] = useState(null);
  const [deleteUnavailability, setDeleteUnavailability] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);

  const User_id = JSON.parse(get_cookie("workauthUser")).user_id;

  const getUnavailability = () => {
    const postData = {
      user_id: User_id,
    };
    axios.post("/get-unavailability", postData).then((res) => {
      setUnavailabilityData(res.data);
      console.log(res);
    });
  };

  useEffect(() => {
    getUnavailability();
  }, []);

  // useEffect(() => {
  //   const calendar = calendarRef.current.getApi();
  //   const currentMonth = calendar.currentData.viewTitle;
  //   setCurrentMonth(currentMonth);
  //   setCurrentMonthIndex(calendar.view.activeStart.getMonth());
  //   calendar.on("datesSet", handleDatesSet);
  // }, []);
  // console.log(currentMonthIndex);
  // console.log(new Date().getMonth());

  // const handleDatesSet = (arg) => {
  //   setCurrentMonth(arg.view.title);
  //   setCurrentMonthIndex(arg.view.activeStart.getMonth());
  // };

  // const handlePrevClick = () => {
  //   console.log(currentMonthIndex);
  //   if (currentMonthIndex >= new Date().getMonth()) {
  //     calendarRef.current.getApi().prev();
  //   }
  // };

  const [currentYears, setCurrentYears] = useState(
    new Date().toLocaleString("en-AU", { year: "numeric" })
  );
  const [currentMonths, setCurrentMonths] = useState(
    new Date().toLocaleString("en-AU", { month: "long" })
  );

  useEffect(() => {
    const calendar = calendarRef.current.getApi();

    // Set initial calendar state
    const currentMonth = calendar.currentData.viewTitle;
    const currentYear = calendar.view.activeStart.getFullYear(); // Get the year
    const currentMonthIndex = calendar.view.activeStart.getMonth(); // Get the month index (0-based)

    setCurrentMonth(currentMonth);
    // setCurrentMonthIndex(currentMonthIndex);
    setCurrentYear(currentYear);

    // Listen for date changes in the calendar
    calendar.on("datesSet", handleDatesSet);
  }, []);

  const handleDatesSet = (arg) => {
    setCurrentMonth(arg.view.title); // Update the displayed month title
    const currentMonthString = arg.view.title;
    const [month, year] = currentMonthString.split(" ");
    setCurrentMonths(month); // Update the month name
    setCurrentYears(year); // Update the year as a number
    setCurrentMonthIndex(arg.view.activeStart.getMonth()); // Update the month index
    setCurrentYear(arg.view.activeStart.getFullYear()); // Update the year
  };

  const handlePrevClick = () => {
    // console.log(currentMonthIndex);
    // if (currentMonthIndex >= new Date().getMonth()) {
    //   calendarRef.current.getApi().prev();
    // }
    // Move to the previous month
    calendarRef.current.getApi().prev();
  };

  // console.log("Calendar Month Index:", currentMonthIndex, currentMonths);
  // console.log("Calendar Year:", currentYears);
  // console.log(
  //   "System Month Index:",
  //   new Date().toLocaleString("en-AU", { month: "long" })
  // );
  // console.log(
  //   "System Year:",
  //   new Date().toLocaleString("en-AU", { year: "numeric" })
  // );

  // Map unavailability data to FullCalendar events
  const events = unavailabilityData?.map((data) => {
    const isFullDay =
      data.all_day === 1 || (!data.start_time && !data.end_time);

    const startDate = isFullDay
      ? data.unavailable_date
      : data.start_time
      ? `${data.unavailable_date} ${data.start_time}`
      : data.unavailable_date;
    const endDate = isFullDay
      ? data.unavailable_date
      : data.end_time
      ? `${data.unavailable_date} ${data.end_time}`
      : data.unavailable_date;

    return {
      id: data.id,
      title: "Unavailable",
      start: startDate,
      end: endDate,
      allDay: data.all_day === 1,
      isFullDay: isFullDay,
      extendedProps: {
        currentDate: data.unavailable_date,
        description: data.reason ? data.reason : "Unavailable",
      },
    };
  });

  const eventContent = (eventInfo) => {
    const { event } = eventInfo;
    const startTime = event.start
      ? moment(event.start, "HH:mm:ss").format("HH:mm A")
      : null;
    const endTime = event.end
      ? moment(event.end, "HH:mm:ss").format("HH:mm A")
      : null;
    const formattedDate = moment(event.extendedProps.currentDate).format(
      "DD/MM/YYYY"
    );

    return (
      <>
        <div className="vstack w-100">
          <p>{formattedDate} </p>
          {event.extendedProps.isFullDay ? (
            <p className=" fw-semibold text-danger mt-2">Fully Unavailable</p>
          ) : (
            startTime &&
            endTime && (
              <p className=" fw-semibold text-black mt-2">
                {startTime} - {endTime}
              </p>
            )
          )}
        </div>
      </>
    );
  };

  function toggle_unavailable_Modal() {
    setUnavailableModal(!UnavailableModal);
  }

  const handleDateClick = (arg) => {
    const clickedDate = arg.dateStr;

    // set null here to reset the time states
    setFromTime(null);
    setToTime(null);

    // Filter unavailabilityData based on clickedEvent
    const filteredData = unavailabilityData.filter(
      (data) => data.unavailable_date === clickedDate
    );

    if (filteredData.length > 0) {
      // Extract start and end times
      const clickedStartTime = filteredData[0].start_time;
      const clickedEndTime = filteredData[0].end_time;
      const allDay = filteredData[0].all_day;

      if (
        clickedStartTime === null &&
        clickedEndTime === null &&
        allDay === 1
      ) {
        // Now you can set these values to your state or use them as needed
        setFromTime("fully_unavailable");
        setToTime(clickedEndTime);
      } else {
        // Now you can set these values to your state or use them as needed
        setFromTime(clickedStartTime);
        setToTime(clickedEndTime);
      }
    } else {
      console.log("No matching data found for clickedDate:", clickedDate);
    }

    const selectedDate = moment(arg.dateStr).format("YYYY-MM-DD");

    const diffInDays = moment(arg.dateStr).diff(moment(new Date()), "days");
    if (diffInDays >= 0) {
      setCurrentDate(selectedDate); // Update selected date
      setSelectedDates([selectedDate]);
      setUnavailableModal(true); // Open the modal directly
    } else {
      toast.warning("Can't add unavailabitiy to past dates");
    }
    console.log("Date clicked:", arg.dateStr); // Log the clicked date
  };

  const handleEventClick = (arg) => {
    const clickedEvent = arg.event._def.extendedProps.currentDate;

    // set null here to reset the time states
    setFromTime(null);
    setToTime(null);

    // Filter unavailabilityData based on clickedEvent
    const filteredData = unavailabilityData.filter(
      (data) => data.unavailable_date === clickedEvent
    );

    if (filteredData.length > 0) {
      // Extract start and end times
      const clickedStartTime = filteredData[0].start_time;
      const clickedEndTime = filteredData[0].end_time;
      const allDay = filteredData[0].all_day;

      if (
        clickedStartTime === null &&
        clickedEndTime === null &&
        allDay === 1
      ) {
        // Now you can set these values to your state or use them as needed
        setFromTime("fully_unavailable");
        setToTime(clickedEndTime);
      } else {
        // Now you can set these values to your state or use them as needed
        setFromTime(clickedStartTime);
        setToTime(clickedEndTime);
      }
    } else {
      console.log("No matching data found for clickedEvent:", clickedEvent);
    }

    // const selectedDate = moment(clickedEvent).format("YYYY-MM-DD");
    // console.log(selectedDate,"clickedEvent");

    // const diffInDays = selectedDate.diff(moment(new Date()), "days");
    // if (diffInDays >= 0) {
    // setCurrentDate(selectedDate); // Update selected date
    // setSelectedDates([selectedDate]);
    // setUnavailableModal(true); // Open the modal directly
    // }

    const selectedDate = moment(clickedEvent);
    const formattedDate = selectedDate.format("YYYY-MM-DD");
    console.log(formattedDate, "clickedEvent");

    const diffInDays = selectedDate.diff(moment(new Date()), "days");
    if (diffInDays >= 0) {
      setCurrentDate(formattedDate); // Update selected date
      setSelectedDates([formattedDate]);
      setUnavailableModal(true); // Open the modal directly
    }
  };

  const handleDateChange = (selectedDates) => {
    const dateOnlySelectedDates = selectedDates.map((date) =>
      moment(date).format("YYYY-MM-DD")
    );
    setSelectedDates(dateOnlySelectedDates);
  };

  const sendUnavailibilityDates = () => {
    if (deleteUnavailability === null) {
      if (fromTime === "" || fromTime === null) {
        toast.warning("Please choose start time");
        return false;
      }
      if (
        fromTime !== "fully_unavailable" &&
        (toTime === "" || toTime === null)
      ) {
        toast.warning("Please choose end time");
        return false;
      }
    }

    const formData = {
      user_id: User_id,
      start_time: fromTime === "fully_unavailable" ? null : fromTime,
      end_time: fromTime === "fully_unavailable" ? null : toTime,
      all_day: fromTime === "fully_unavailable" ? "fully_unavailable" : null,
      unavailable_dates: selectedDates,
      deleteUnavailability: deleteUnavailability,
    };
    axios
      .post("/my-unavailability", formData)
      .then((res) => {
        if (res.error == 0) {
          toast.success(res.message);
          // toast.success(res.message[0]);
          getUnavailability();
          setUnavailableModal(false); // Open the modal directly
          setDeleteUnavailability(null);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setFromTime(null), setToTime(null));
  };

  const generateTimeOptions = () => {
    const options = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 15) {
        const hour = h < 10 ? `0${h}` : `${h}`;
        const minute = m === 0 ? "00" : `${m}`;
        const time = `${hour}:${minute}`;
        options.push({ label: formatTime(time), value: time });
      }
    }
    options.unshift({ label: "Fully UnAvailable", value: "fully_unavailable" });
    return options;
  };

  // Function to format time as AM/PM
  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    const formattedHour = parseInt(hour, 10) % 12 || 12;
    const period = parseInt(hour, 10) < 12 ? "AM" : "PM";
    return `${formattedHour}:${minute} ${period}`;
  };

  const timeOptions = generateTimeOptions();

  document.title = "My UnAvailability | Get Rostered";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="My Unavailability" pageTitle="Get Rostered" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="header-title mb-3">{currentMonth}</h4>
                    </div>
                    <div className="d-flex">
                      {/* <button
                        className="btn btn-sm btn-outline-dark me-2 rounded-3"
                        onClick={handlePrevClick}
                        disabled={currentMonthIndex < new Date().getMonth()}
                      >
                        <i className="mdi mdi-arrow-left-thick fs-14"></i>
                      </button> */}
                      <button
                        className="btn btn-sm btn-outline-dark me-2 rounded-3"
                        onClick={handlePrevClick}
                        disabled={
                          currentYears ===
                            new Date().toLocaleString("en-AU", {
                              year: "numeric",
                            }) &&
                          currentMonths ===
                            new Date().toLocaleString("en-AU", {
                              month: "long",
                            })

                          // || (currentYear === new Date().getFullYear() &&
                          // currentMonthIndex < new Date().getMonth())

                          // ||(currentYear !== new Date().getFullYear() &&
                          // currentMonthIndex > new Date().getMonth())
                        }
                      >
                        <i className="mdi mdi-arrow-left-thick fs-14"></i>
                      </button>
                      <button
                        className="btn btn-sm btn-outline-primary fs-14 me-2 rounded-3"
                        onClick={() => calendarRef.current.getApi().today()}
                      >
                        Today
                      </button>
                      <button
                        className="btn btn-sm btn-outline-dark fs-14 rounded-3"
                        onClick={() => calendarRef.current.getApi().next()}
                      >
                        <i className="mdi mdi-arrow-right-thick"></i>
                      </button>
                    </div>
                  </div>
                  <p
                    className="sub-header text-muted"
                    style={{ maxWidth: "75%" }}
                  >
                    Please note: Any dates marked as unavailable here will
                    override your general availability settings. Ensure you
                    update this calendar for specific dates you cannot work,
                    such as personal events or appointments.
                  </p>
                </CardHeader>
                <CardBody>
                  <FullCalendar
                    ref={calendarRef}
                    className="fc-dayGridMonth-view"
                    schedulerLicenseKey="0324696433-fcs-1690265247"
                    plugins={[
                      resourceTimelinePlugin,
                      BootstrapTheme,
                      dayGridPlugin,
                      interactionPlugin,
                      resourceTimeGridPlugin,
                      timeGridPlugin,
                    ]}
                    initialView="dayGridMonth" // or 'resourceTimelineWeek', 'resourceTimelineMonth', etc.
                    events={events}
                    editable={true}
                    eventContent={eventContent}
                    headerToolbar={{
                      left: "",
                      right: "",
                    }}
                    eventClick={handleEventClick}
                    dateClick={handleDateClick}
                    customButtons={{
                      view: {
                        // text: (
                        //     <button className="btn btn-outline-info" >
                        //         View options
                        //     </button>
                        // ),
                      },
                      spacerButton: {
                        // Define a spacer button
                        text: "",
                        click: function () {}, // Empty click function
                      },
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={UnavailableModal}
            toggle={() => {
              toggle_unavailable_Modal();
              setDeleteUnavailability(null);
            }}
            size=""
            id="unavailabilityModal"
          >
            <ModalHeader className=" position-relative pe-0">
              {moment(currentDate).format("MMMM DD, YYYY")} - Unavailability
              <Button
                type="button"
                className="btn-close"
                style={{ position: "absolute", right: "25px", top: "25px" }}
                onClick={() => {
                  setUnavailableModal(false);
                }}
                aria-label="Close"
              ></Button>
            </ModalHeader>
            <ModalBody className="unavailability_calender pt-2 pe-0">
              <p className="mb-2">
                Select the date(s) you want to assign specific hours
              </p>
              <Flatpickr
                name="unavailabilityDates"
                className="form-control"
                id="datepicker-publish-input"
                placeholder="Select date(s)"
                options={{
                  mode: "multiple",
                  enableTime: false,
                  dateFormat: "Y-m-d",
                  inline: true, // Use inline mode
                }}
                onChange={handleDateChange}
                value={selectedDates || []}
              />
              {console.log(currentDate)}
              <form>
                <div className="mb-2">
                  <Row>
                    <Col md={12}>
                      <div className="mt-3">
                        <Label className="" for="unavailableTime">
                          What hours are you unavailable?
                        </Label>
                      </div>
                    </Col>
                    <Col md={fromTime === "fully_unavailable" ? 6 : 5}>
                      <div className="mb-3 hstack position-relative">
                        <select
                          className="form-select"
                          value={fromTime || "default"}
                          onChange={(e) => {
                            setFromTime(e.target.value);
                            console.log(e.target.value);
                            if (e.target.value === "fully_unavailable") {
                              setIsUnAvailable(true);
                            }
                          }}
                        >
                          <option value="default" disabled>
                            Select a time
                          </option>
                          {timeOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {fromTime !== "fully_unavailable" && (
                          <span
                            className=" position-absolute"
                            style={{ right: "-15px" }}
                          >
                            -
                          </span>
                        )}
                        {fromTime === "fully_unavailable" && (
                          <>
                            <span
                              className="fs-16 text-danger position-absolute"
                              onClick={() => (
                                setFromTime("default"),
                                setDeleteUnavailability("delete")
                              )}
                              style={{ right: "-20px", cursor: "pointer" }}
                            >
                              x
                            </span>
                          </>
                        )}
                      </div>
                    </Col>
                    {isUnAvailable && fromTime !== "fully_unavailable" && (
                      <>
                        <Col md={5}>
                          <div className="mb-3">
                            <select
                              className="form-select"
                              value={toTime || "default"}
                              onChange={(e) => {
                                setToTime(e.target.value);
                              }}
                            >
                              <option value="default" disabled>
                                Select a time
                              </option>
                              {timeOptions
                                .filter(
                                  (option) =>
                                    option.value > fromTime &&
                                    option.value !== "fully_unavailable"
                                )
                                .map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </Col>
                        <Col md={1}>
                          <span
                            className="fs-16 text-danger position-absolute"
                            onClick={() => (
                              setFromTime("default"),
                              setToTime("default"),
                              setDeleteUnavailability("delete")
                            )}
                            style={{
                              right: "10px",
                              top: "5px",
                              cursor: "pointer",
                            }}
                          >
                            x
                          </span>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              </form>
            </ModalBody>
            <ModalFooter className="hstack justify-content-start pe-0">
              <Button
                color="dark"
                className="btn btn-dark"
                onClick={() => {
                  setUnavailableModal(false);
                  setDeleteUnavailability(null);
                  setFromTime(null);
                  setToTime(null);
                }}
              >
                Cancel
              </Button>
              <Button
                color="success"
                onClick={() => {
                  sendUnavailibilityDates();
                }}
              >
                Apply
              </Button>
            </ModalFooter>
          </Modal>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MyUnavailability;
