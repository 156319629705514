import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Label,
  Col,
  Row,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import AuthSlider from "../AuthenticationInner/authCarousel";
import { useNavigate } from "react-router-dom";
//formik
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import AppleLogin from "react-apple-login";
import { jwtDecode } from "jwt-decode";
import { add_cookie } from "../../helpers/get_cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LogoPink from "../../assets/images/LogoPink.png";

const Register = () => {
  document.title = "Cover SignUp | Velzon - React Admin & Dashboard Template";
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [checkboxError, setCheckboxError] = useState("");
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value.trim());
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value.trim());
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value.trim());
  };
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    // Validate password
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);

    // Validate password
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the checkbox is checked
    if (!isCheckboxChecked) {
      // Display an error message
      setCheckboxError(
        "Please agree to the Terms of Service and Privacy Policy"
      );
      return;
    }

    // Perform your validation here
    if (
      !validateFirstName(firstName) ||
      !validateLastName(lastName) ||
      !validateEmail(email) ||
      !validatePassword(password) ||
      !validateConfirmPassword(confirmPassword)
    ) {
      // Validation failed, don't proceed with submission
      return;
    }
    setIsLoading(true);

    let formData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
    };

    // Validation passed, proceed with form submission
    console.log("Validation passed. Register calling here");
    // Axios request
    try {
      const response = await axios.post("/register", formData);
      console.log(response.status);

      if (response.status == "205" || response.status == "200") {
        navigate("/twoStepVerify", { state: { email: email } });
        setIsLoading(false);
      } else if (response.status == "209") {
        toast.warning("User already signed up. Redirecting to login page", {
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate("/login");
        }, 2000);
        setIsLoading(false);
      } else {
        setConfirmPasswordError(
          "Password does not meet policy requirements: It must contain at least one special symbol character",
          {
            autoClose: 2000,
          }
        );
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsLoading(false);
    }
  };
  const validateFirstName = (value) => {
    // Validate non-empty and character set
    if (!value || !/^[a-zA-Z]+$/.test(value)) {
      // Display an error message
      setFirstNameError("Please enter a valid first name");
      return false;
    }
    setFirstNameError("");
    return true;
  };
  const validateLastName = (value) => {
    // Validate non-empty and character set
    if (!value || !/^[a-zA-Z]+$/.test(value)) {
      // Display an error message
      setLastNameError("Please enter a valid last name");
      return false;
    }
    setLastNameError("");
    return true;
  };
  const validateEmail = (value) => {
    // Simple email format validation
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      // Display an error message
      setEmailError("Please enter a valid email address");
      return false;
    }
    setEmailError("");
    return true;
  };
  const validatePassword = (value) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
    const isValid = passwordRegex.test(value);
    if (!value || !isValid) {
      setPasswordError(
        "Password must be at least 8 characters long, contain at least one number, one uppercase letter, one lowercase letter, and one special character."
      );
      return false;
    }
    setPasswordError("");
    return true;
  };
  const validateConfirmPassword = (value) => {
    if (!value) {
      setConfirmPasswordError("Please re-enter your password.");
      return false;
    } else if (value !== password) {
      setConfirmPasswordError("Passwords do not match!");
      return false;
    }
    setConfirmPasswordError("");
    return true;
  };

  const onGoogleSignUp = async (token) => {
    console.log(jwtDecode(token.credential));
    const decoded_info = jwtDecode(token.credential);
    const formData = {
      google_access_token: token.credential,
    };
    const response = await axios.post("/register-with-google", formData);
    console.log(response.status);

    if (response.status == "205" || response.status == "200") {
      navigate("/twoStepVerify", { state: { email: decoded_info.email } });
    } else if (response.status == "209") {
      onGoogleLogin(token);
    }
  };

  const onGoogleLogin = async (token) => {
    //let isChecked = document.getElementById('auth-remember-check').checked;
    const decoded_info = jwtDecode(token.credential);
    const formData = {
      idToken: token.credential,
    };
    const response = await axios.post("/google-login", formData);
    console.log(response.status);

    if (
      response.status == "200" &&
      response.message != "User registered successfully"
    ) {
      add_cookie(response);
      navigate("/user-profile");
    } else if (response.message === "User is not confirmed.") {
      toast.warning(
        "User is not verified!. Redirecting to verification page...",
        { autoClose: 2000 }
      );
      setTimeout(() => {
        navigate("/twoStepVerify", { state: { email: formData.email } });
        setIsLoading(false);
      }, 3000);
    } else if (response.message === "Incorrect username or password.") {
      toast.error("Incorrect user email or password!");
      setIsLoading(false);
    } else if (response.message === "User registered successfully") {
      navigate("/twoStepVerify", { state: { email: decoded_info.email } });
      setIsLoading(false);
    }
  };
  return (
    <div className=" overflow-hidden d-flex align-items-center justify-content-center">
      <Card className="auth-page-contents mb-0 align-items-center justify-content-center">
        <Row className="g-0 w-100">
          <AuthSlider />

          <div className="col-lg-6 d-flex align-items-center custom-col">
            <div className="overflow-hidden" style={{ width: "100%" }}>
              <div className="register-page">
                <div className="text-center mt-sm-1 mb-2">
                  <div className="logo-mobile">
                    <Row
                      className="d-flex justify-content-center"
                      style={{ paddingLeft: "0" }}
                    >
                      <Col
                        md={8}
                        className="text-start"
                        style={{ paddingLeft: "0" }}
                      >
                        <Link to="/dashboard" className="d-block">
                          <img
                            src={LogoPink}
                            alt=""
                            className="img-fluid h-30 d-block"
                          />
                        </Link>
                      </Col>
                    </Row>
                  </div>

                  <div className="logo-ipad">
                    <Row
                      className="d-flex justify-content-center"
                      style={{ paddingLeft: "0" }}
                    >
                      <Col
                        md={8}
                        className="text-start"
                        style={{ paddingLeft: "0" }}
                      >
                        <Link to="/dashboard" className="d-block">
                          <img
                            src={LogoPink}
                            alt=""
                            className="img-fluid h-30 d-block"
                          />
                        </Link>
                      </Col>
                    </Row>
                  </div>

                  <div className="font-mobile mt-3">
                    <Label>Create New Account</Label>
                  </div>

                  <div className="font-subtitle text-center mt-sm-3 mb-3">
                    <Label>Enter Your information below and join us!</Label>
                  </div>
                </div>
                <div className="mt-1">
                  <form
                    className="needs-validation"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <div className="p-4 pb-0 position-relative">
                      <i
                        className="ri-user-fill position-absolute mt-2 ms-2 icon-left"
                        style={{ color: "grey" }}
                      ></i>
                      <Input
                        type="text"
                        className={`form-control  pe-5  ${
                          firstNameError ? "is-invalid" : ""
                        }`}
                        id="firstName"
                        placeholder="Enter first name"
                        value={firstName}
                        onChange={handleFirstNameChange}
                        required
                        style={{ borderRadius: "10px", paddingLeft: "2.5rem" }}
                      />
                      {firstNameError && (
                        <div className="invalid-feedback">{firstNameError}</div>
                      )}
                    </div>
                    <div className="p-4 pb-0 position-relative">
                      <i
                        className="ri-user-fill position-absolute mt-2 ms-2 icon-left"
                        style={{ color: "grey" }}
                      ></i>
                      <Input
                        type="text"
                        className={`form-control pe-5 ${
                          lastNameError ? "is-invalid" : ""
                        }`}
                        id="lastName"
                        placeholder="Enter last name"
                        value={lastName}
                        onChange={handleLastNameChange}
                        required
                        style={{ borderRadius: "10px", paddingLeft: "2.5rem" }}
                      />
                      {lastNameError && (
                        <div className="invalid-feedback">{lastNameError}</div>
                      )}
                    </div>

                    <div className="p-4 pb-0 position-relative">
                      <i
                        className="ri-mail-fill position-absolute mt-2 ms-2 icon-left"
                        style={{ color: "grey" }}
                      ></i>
                      <Input
                        type="email"
                        id="useremail"
                        placeholder="Enter email address"
                        className={`form-control ${
                          emailError ? "is-invalid" : ""
                        }`}
                        value={email}
                        onChange={handleEmailChange}
                        required
                        style={{ borderRadius: "10px", paddingLeft: "2.5rem" }}
                      />
                      {emailError && (
                        <div className="invalid-feedback">{emailError}</div>
                      )}
                    </div>

                    <div className="p-4 pb-0 position-relative auth-pass-inputgroup">
                      <i
                        className="ri-lock-2-fill position-absolute mt-2 ms-2 icon-left"
                        style={{ color: "grey" }}
                      ></i>
                      <Input
                        type={passwordShow ? "text" : "password"}
                        className={`form-control pe-5 password-input ${
                          passwordError ? "is-invalid" : ""
                        }`}
                        placeholder="Enter password"
                        id="password-input"
                        name="password"
                        value={password}
                        onChange={handlePasswordChange}
                        onPaste={(e) => e.preventDefault()}
                        required
                        style={{ borderRadius: "10px", paddingLeft: "2.5rem" }}
                      />

                      <Button
                        color="link"
                        onClick={() => setPasswordShow(!passwordShow)}
                        className="btn btn-link position-absolute me-4 mt-4 end-0 top-0 text-decoration-none text-muted"
                        type="button"
                        id="password-addon"
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </Button>
                      {passwordError && (
                        <div className="invalid-feedback">{passwordError}</div>
                      )}
                    </div>

                    <div className="p-4 pb-0 position-relative auth-pass-inputgroup">
                      <i
                        className="ri-lock-2-fill position-absolute mt-2 ms-2 icon-left"
                        style={{ color: "grey" }}
                      ></i>

                      <Input
                        type={confirmPasswordShow ? "text" : "password"}
                        className={`form-control pe-5 password-input ${
                          confirmPasswordError ? "is-invalid" : ""
                        }`}
                        placeholder="Enter password"
                        id="password-input"
                        name="password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        onPaste={(e) => e.preventDefault()}
                        required
                        style={{ borderRadius: "10px", paddingLeft: "2.5rem" }}
                      />

                      <Button
                        color="link"
                        onClick={() =>
                          setConfirmPasswordShow(!confirmPasswordShow)
                        }
                        className="btn btn-link position-absolute me-4 mt-4 end-0 top-0 text-decoration-none text-muted"
                        type="button"
                        id="password-addon"
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </Button>
                      {confirmPasswordError && (
                        <div className="invalid-feedback">
                          {confirmPasswordError}
                        </div>
                      )}
                    </div>

                    {/* <div className="mt-3" style={{ display: "flex" }}>

                                                            <i className="ri-checkbox-circle-fill btn-success fs-20" style={{ color: '#3db583', marginRight: "5px" }}></i>
                                                            <p className="mb-0 fs-12"> We won't be sharing your info with anyone. We won't be sending promotional emails. For details, see <Link to="/privacy-policy" className="fw-semibold text-primary text-decoration-underline">Privacy Policy</Link> </p>
                                                        </div> */}

                    <div
                      id="password-contain"
                      className="p-3 bg-light mb-2 rounded"
                    >
                      <h5 className="fs-13">Password must contain:</h5>
                      <p id="pass-length" className="invalid fs-12 mb-2">
                        Minimum <b>8 characters</b>
                      </p>
                      <p id="pass-lower" className="invalid fs-12 mb-2">
                        At <b>lowercase</b> letter (a-z)
                      </p>
                      <p id="pass-upper" className="invalid fs-12 mb-2">
                        At least <b>uppercase</b> letter (A-Z)
                      </p>
                      <p id="pass-number" className="invalid fs-12 mb-0">
                        A least <b>number</b> (0-9)
                      </p>
                    </div>
                    <div className="form-check mt-2 ms-4">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="auth-remember-check"
                        onChange={() => {
                          setIsCheckboxChecked(!isCheckboxChecked);
                          setCheckboxError(""); // Clear the error message when the checkbox state changes
                        }}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="auth-remember-check"
                        style={{ fontWeight: "200", fontSize: "12px" }}
                      >
                        I agree with the{" "}
                        <span
                          style={{
                            color: "#48008A",
                            fontWeight: "300",
                            fontSize: "13px",
                          }}
                        >
                          Terms of Services
                        </span>{" "}
                        and{" "}
                        <span
                          style={{
                            color: "#48008A",
                            fontWeight: "300",
                            fontSize: "13px",
                          }}
                        >
                          Privacy Policy
                        </span>
                      </Label>
                      {checkboxError && (
                        <div className="text-danger">{checkboxError}</div>
                      )}
                    </div>

                    <div className="p-4 pb-0 mb-4">
                      <Button
                        className="btn btn-signup  w-100 text-white"
                        disabled={isLoading ? true : false}
                        type="submit"
                        style={{ borderRadius: "10px", border: "none" }}
                      >
                        {isLoading ? (
                          <Spinner size="sm" className="me-2">
                            {" "}
                            Loading...{" "}
                          </Spinner>
                        ) : null}{" "}
                        Sign Up
                      </Button>
                    </div>
                    <div
                      style={{
                        display: "block",
                        width: "fit-content",
                        margin: "auto",
                      }}
                    >
                      <GoogleLogin
                        className="google-btn"
                        size={"large"}
                        text={"signup_with"}
                        onSuccess={(credentialResponse) => {
                          console.log(credentialResponse);
                          onGoogleSignUp(credentialResponse);
                        }}
                        onError={() => {
                          console.log("Login Failed");
                        }}
                      />
                    </div>
                    <div
                      className="mt-2"
                      style={{
                        display: "block",
                        width: "fit-content",
                        margin: "auto",
                      }}
                    >
                      <AppleLogin
                        clientId="apple.getrostered.work"
                        scope="name email"
                        responseType="code id_token"
                        responseMode="form_post"
                        redirectURI="https://devwork.getrostered.app/server/public/api/apple-login-callback"
                        designProp={{ height: 40, width: 180 }}
                      />
                    </div>
                  </form>
                </div>
                <div className="mt-3 p-2 text-center">
                  <p
                    className="mb-0"
                    style={{ textAlign: "center", fontSize: "15px" }}
                  >
                    Joining an existing account?{" "}
                    <Link
                      to="/login"
                      className="fw-semibold"
                      style={{ fontWeight: "300", color: "black" }}
                    >
                      Click here
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Card>
      <ToastContainer />
      {/* </Container> */}
      {/* </footer> */}
    </div>
  );
};

export default Register;
